import React, {useState} from "react";
import {tabList} from "./data";
import Link from "next/link";
// scss
import styles from "./academicService.module.scss";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";


function AcademicService(props) {
  const [activeTab, setActiveTab] = useState("2");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }

  const { linkLocale } = props;
  return <>
    <section className={`${styles.academicService} pb-0`}>
      <div className="container">
        <div className="text-center">
          <h2 className="section-title">Academic Paper Writing Service</h2>
          <p className="desc">
            Our essay writers will gladly help you with:
          </p>
        </div>
      </div>
      <div className={styles.academicDetails}>
        <Container>
          <Tab.Container defaultActiveKey={activeTab}>
            <Nav variant="tabs" className={`${styles.nav} justify-content-center nav-pills`}>
              {
                tabList.map(({ tabId, title }) => (
                  <Nav.Item key={tabId} className={styles.navItem}>
                    <Nav.Link
                      className={`${styles.academicServiceType} ${activeTab === tabId ? styles.active : ''}`}
                      onClick={() => toggle(tabId)}
                      eventKey={tabId}>
                      <span>{title}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))
              }
            </Nav>
            <Tab.Content className={styles.tabContent}>
              {
                tabList.map(({ tabId, listItems }) => (
                      <Tab.Pane eventKey={tabId} key={tabId} className={styles.tabPane}>
                        {tabId === activeTab && (
                            <div className="tab-pane-box">
                              <ul className={styles.serviceList}>
                                {listItems.map(name => (
                                        <li key={name}><span className={`${styles.listLink} nav-link`}>{name}</span></li>
                                    )
                                )}
                              </ul>
                            </div>
                        )}
                      </Tab.Pane>
                  ))
              }
            </Tab.Content>
          </Tab.Container>
          <div className="text-center mt-5">
            <Link
              href={"/top-writers"}
              locale={linkLocale}
              prefetch={false}
              className="btn secondary-btn inline-button-click"
              id="findWriter">
              Find Your Writer
            </Link>
          </div>
        </Container>
      </div>
    </section>
  </>;
}

export default AcademicService;
