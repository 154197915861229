export const tabList = [
    {
        tabId: "1",
        title: "Paperwork",
        listItems: [
            "Essay",
            "Case Study",
            "Report",
            "Research Paper",
            "Literature Review",
            "Business Plan",
            "Reflective Writing",
            "Term Paper",
            "Thesis / Dissertation",
            "Research Proposal",
            "Presentation or Speech",
            "Annotated Bibliography",
            "Article Review" ,
            "Book / Movie Review",
            "Editing and proofreading",
            "Admission Essay",
            "Creative Writing",
            "Critical Thinking / Review",
            "Book Reviews",
            "Other"
        ]
    },
    {
        tabId: "2",
        title: "Coursework / Homework",
        listItems: [
            "Homework Assignment",
            "Statistics Assignment",
            "Physics Assignment",
            "Engineering Assignment",
            "Biology Assignment",
            "Chemistry Assignment",
            "Math Assignment",
            "Geography Assignment",
            "Other"
        ]
    },
    {
        tabId: "3",
        title: "Other Assignments",
        listItems: [
            "Multiple Choice Questions",
            "Short Answer Questions",
            "Word Problems"
        ]
    }
]